import React, { useContext, useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import mx from 'mixpanel-browser';
import { bool } from 'prop-types';
import { Form, useNotify, useReference, useRefresh, useShowContext, useUpdate } from 'react-admin';
import { useNavigate } from 'react-router';

import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider } from '@mui/material';

import * as resources from '@/api/resources';
import { QuoteContext } from '@/providers/Quote';
import CardInput from '@/shared/stripe/CardInput';

const FormControls = ({ isSaving, isStripeLoading }) => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();

  const navigateBack = () => navigate(`/${resources.QUOTES}/${quoteRecord.id}/edit`);

  const isUpdating = isSaving || isStripeLoading;

  return (
    <Box display="flex" justifyContent="space-between">
      <Button variant="contained" color="neutral" onClick={navigateBack}>
        Back
      </Button>
      <Button
        variant="contained"
        type="submit"
        disabled={isUpdating ?? null}
        endIcon={isUpdating ? <CircularProgress size={18} /> : null}
      >
        Save
      </Button>
    </Box>
  );
};

FormControls.propTypes = {
  isSaving: bool.isRequired,
  isStripeLoading: bool.isRequired,
};

const PaymentMethodForm = () => {
  const { accountId } = useContext(QuoteContext);

  const { referenceRecord: accountRecord } = useReference({
    reference: resources.CUSTOMERS,
    id: accountId,
    options: { enabled: Boolean(accountId) },
  });

  return (
    <Box>
      <CardInput cardholderName={`${accountRecord.first_name} ${accountRecord.last_name}`} />
    </Box>
  );
};

const UpdateAccountPaymentMethod = () => {
  const { record: quoteRecord } = useShowContext();
  const [update, { isLoading: isSaving }] = useUpdate();
  const { accountId } = useContext(QuoteContext);
  const navigate = useNavigate();
  const notify = useNotify();
  const refresh = useRefresh();

  const elements = useElements();
  const stripe = useStripe();

  const [isStripeLoading, setIsStripeLoading] = useState(false);

  const navigateOverview = () => navigate(`/${resources.QUOTES}/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Account Payment Method updated', {
      quoteId: quoteRecord?.id,
    });
    refresh();
    navigateOverview();
    notify('Payment method updated on account - may take a few minutes to reflect changes', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error updating Payment Method on Account', {
      quoteId: quoteRecord?.id,
      error,
    });
    if (error.status === 402) {
      notify(
        `Error occurred updating payment method on account - Card declined - ${error.body?.detail ?? 'Unknown Reason'}`,
        { type: 'error' },
      );
    } else {
      notify(`Error occurred updating payment method on account - ${error.status} - ${error.body?.detail ?? error}`, {
        type: 'error',
      });
    }
  };

  const onSubmit = (data) => {
    setIsStripeLoading(true);
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement, { name: data.cardholderName }).then((result) => {
      if (result.token) {
        const paymentMethod = {
          provider: 'STRIPE_TOKEN',
          token: result.token.id,
        };
        update(
          resources.CUSTOMERS,
          {
            id: accountId,
            data: paymentMethod,
            previousData: {},
            meta: {
              subResource: 'payment_method',
            },
          },
          {
            onSuccess,
            onError,
          },
        );
      } else if (result.error) {
        notify(result.error.message, { type: 'error' });
      }
      setIsStripeLoading(false);
    });
  };

  if (!quoteRecord || !accountId) return null;

  return (
    <Form onSubmit={onSubmit}>
      <Card mt={3} component={Box}>
        <CardHeader title="Add Payment Method" />
        <Divider />
        <PaymentMethodForm />
        <Divider />
        <CardContent>
          <FormControls isSaving={isSaving} isStripeLoading={isStripeLoading} />
        </CardContent>
      </Card>
    </Form>
  );
};

export default UpdateAccountPaymentMethod;
