import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import _ from 'lodash';

import * as resources from '@/api/resources';
import { MARKET_TIMEZONES } from '@/constants/marketTimezones';
import { SERVICES } from '@/constants/serviceName';
import { HttpError } from 'react-admin';
import toPascalCase from '@/utils/toPascalCase';

dayjs.extend(tz);

const zippyTransform = (data, create) => {
  const retData = _.cloneDeep(data);

  // Zippyshell orders - Special Location Handling
  // First location is warehouse, second location is customer

  // First step - take warehouse location and create a new duplicate location with "Other" as property type
  // First warehouse address will be forced to "Commercial" type when added by user
  // Second warehouse address will have "Other" type when we generate it below
  // This ensures unique hash in order to have the "same" address on the transit service group

  const warehouseLocation1 = retData.locations[0];
  const customerLocation = retData.locations[1];

  return new Promise((resolve, reject) => {
    const warehouseLocationInput = _.clone(warehouseLocation1.location);

    if (warehouseLocationInput.property_type !== 'COMMERCIAL') {
      throw new HttpError('Please select "Commercial" for the warehouse address property type', 422);
    }

    delete warehouseLocationInput.id;
    warehouseLocationInput.property_type = 'OTHER';

    create(
      resources.LOCATIONS,
      {
        data: warehouseLocationInput,
      },
      {
        onError: (error) => {
          reject(error);
        },
        onSuccess: (locData) => {
          resolve(locData);
        },
      },
    );
  })
    .then((locationData) => {
      // Recreating full location object to match structure of others
      const warehouseLocation2 = {
        location: {
          ...locationData,
          line_1: toPascalCase(locationData.line_1),
          line_2: toPascalCase(locationData.line_2),
          city: toPascalCase(locationData.city),
        },
        location_id: locationData.id,
      };

      const laborLocations = [customerLocation.location.id];
      const transitLocations = [
        warehouseLocation1.location.id,
        customerLocation.location.id,
        warehouseLocation2.location.id,
      ];

      retData.service_groups = retData.service_groups?.map((serviceGroup) => {
        const startDateTime = dayjs(retData.preferred.start_date)
          .hour(retData.preferred.start_time)
          .minute(0)
          .second(0)
          .millisecond(0);

        const timezone =
          retData.locations?.[0].location.timezone ?? MARKET_TIMEZONES[retData.locations?.[0].location.market_id];

        if (serviceGroup.service_codes.includes(SERVICES.TRANSIT.id)) {
          return {
            ...serviceGroup,
            locations: transitLocations,
            start_datetime: startDateTime.tz(timezone, true).toISOString(),
          };
        }

        return {
          ...serviceGroup,
          locations: laborLocations,
          // Add 1 hour to labor to stagger services - truck starts one hour earlier to pickup shell from warehouse
          start_datetime: startDateTime.add(1, 'hour').tz(timezone, true).toISOString(),
        };
      });

      return retData;
    })
    .catch((error) => {
      if (error.body?.detail) {
        return {
          error: {
            ...error,
            message: error.body.detail,
          },
        };
      }
      return {
        error: {
          message: error.message,
          status: error.status ?? 400,
        },
      };
    });
};

export default zippyTransform;
