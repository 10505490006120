import React, { useContext } from 'react';

import { useGetOne, useShowContext } from 'react-admin';

import { AttachMoney, Lock, NoEncryption, QuestionMark } from '@mui/icons-material';
import { Alert, Box, Card, CardContent, CardHeader, Divider, Tooltip, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { FEE_TYPES } from '@/constants/quotes';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { QuoteContext } from '@/providers/Quote';
import formatCurrency from '@/utils/currency/formatCurrency';
import getProductName from '@/utils/productName/getProductName';
import getServiceName from '@/utils/serviceName/getServiceName';

import AddPromoCode from './AddPromoCode';
import RemovePromoCode from './RemovePromoCode';

const ServiceGroups = () => {
  const { record: quoteRecord } = useShowContext();

  if (!quoteRecord) return null;

  return (
    <Box>
      {quoteRecord.service_groups.map((serviceGroup) => (
        <Box key={serviceGroup.id}>
          {serviceGroup.services.map((service) => (
            <Box display="flex" justifyContent="space-between" key={service.id}>
              <Box>
                <Typography>{getServiceName(service.service_id)}</Typography>
              </Box>
              <Typography>
                {service.estimated_pricing_strategy === 'HOURLY'
                  ? formatCurrency(service.quoted_unit_price * serviceGroup.duration)
                  : formatCurrency(service.quoted_unit_price)}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
      <Box my={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Service Total</Typography>
        <Typography variant="h6">{formatCurrency(quoteRecord.totals?.service_total)}</Typography>
      </Box>
    </Box>
  );
};

const Products = () => {
  const { record: quoteRecord } = useShowContext();

  if (!quoteRecord) return null;

  return (
    <Box>
      {quoteRecord.products?.map((product) => (
        <Box key={product.id} display="flex" justifyContent="space-between">
          <Typography>{getProductName(product.product_id)}</Typography>
          <Typography>{formatCurrency(product.estimated_unit_price)}</Typography>
        </Box>
      ))}
      {quoteRecord.products?.length ? (
        <Box my={1}>
          <Divider />
        </Box>
      ) : null}
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Product Total</Typography>
        <Typography variant="h6">{formatCurrency(quoteRecord.totals?.product_total)}</Typography>
      </Box>
    </Box>
  );
};

const Fees = () => {
  const { record: quoteRecord } = useShowContext();

  const fees = quoteRecord?.price_adjustments?.filter((adjustment) => adjustment.adjustment_family === 'Fee');

  if (!quoteRecord || !fees?.length) return null;

  return (
    <Box>
      {fees.map((fee) => (
        <Box key={fee.id} display="flex" justifyContent="space-between">
          <Tooltip title={fee.adjustment_description}>
            <span>
              <Typography>{FEE_TYPES[fee.adjustment_id]?.name ?? 'Unknown Fee'}</Typography>
            </span>
          </Tooltip>
          <Typography>{formatCurrency(fee.estimated_amount)}</Typography>
        </Box>
      ))}
      <Box my={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Fee Total</Typography>
        <Typography variant="h6">{formatCurrency(quoteRecord.totals.fee_total)}</Typography>
      </Box>
    </Box>
  );
};

const PromoCode = () => {
  const { record: quoteRecord } = useShowContext();

  const promoCodes = quoteRecord?.price_adjustments?.filter(
    (adjustment) => adjustment.adjustment_family === 'Reduction' && adjustment.adjustment_id === 'PROMOCODE',
  );

  if (!quoteRecord || !promoCodes) return null;

  return (
    <>
      {promoCodes?.map((promoCode) => (
        <Box display="flex" justifyContent="space-between" mb={1} alignItems="center">
          <Box display="flex" alignItems="center">
            <RemovePromoCode adjustmentId={promoCode.id} />
            <TextFieldWrapper value={promoCode.name} />
          </Box>
          <TextFieldWrapper value={promoCode.adjustment_type === 'DOLLARS' ? 'Amount' : 'Percent'} />
          {promoCode.adjustment_type === 'DOLLARS' ? (
            <TextFieldWrapper value={formatCurrency(promoCode.adjustment_value)} />
          ) : (
            <TextFieldWrapper value={`${promoCode.adjustment_value}%`} />
          )}
        </Box>
      ))}
      <AddPromoCode />
    </>
  );
};

const OrderTotalDiff = () => {
  const { record: quoteRecord } = useShowContext();
  const { canPriceLock, orderId } = useContext(QuoteContext);

  const { data: orderInvoice } = useGetOne(
    resources.ORDERS,
    { id: orderId, meta: { subResource: 'invoice', filter: { invoice_status: 'completed' } } },
    { enabled: Boolean(orderId) },
  );

  if (!quoteRecord || !orderId || !orderInvoice) return null;

  const totalDiff = quoteRecord.totals.total - orderInvoice.total;

  let diffColor = 'neutral';

  if (totalDiff < 0) {
    diffColor = 'primary';
  } else if (totalDiff > 0) {
    diffColor = 'accent.main';
  }

  const allServicesPriceLocked = quoteRecord.service_groups?.every((serviceGroup) =>
    serviceGroup.services?.every((service) => service.lock_price),
  );
  const allServicesPriceUnlocked = quoteRecord.service_groups?.every((serviceGroup) =>
    serviceGroup.services?.every((service) => !service.lock_price),
  );

  const servicePriceLockMixed = !allServicesPriceLocked && !allServicesPriceUnlocked;

  return (
    <>
      <Box my={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between" pt={1}>
        <Typography>Current Order Total</Typography>
        <Typography>{formatCurrency(orderInvoice.total)}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Difference</Typography>
        <Typography variant="h6" color={diffColor}>
          {formatCurrency(totalDiff)}
        </Typography>
      </Box>
      {canPriceLock ? (
        <>
          <Box my={1}>
            <Divider />
          </Box>
          {allServicesPriceLocked ? (
            <Box mt={2}>
              <Alert
                severity="warning"
                variant="outlined"
                icon={
                  <Box display="flex" alignItems="center">
                    <AttachMoney />
                    <Lock />
                  </Box>
                }
              >
                <Typography variant="body2">
                  All service prices are <strong>LOCKED</strong>, no changes to service pricing will apply to order
                </Typography>
              </Alert>
            </Box>
          ) : null}
          {allServicesPriceUnlocked ? (
            <Box mt={2}>
              <Alert
                severity="warning"
                variant="outlined"
                icon={
                  <Box display="flex" alignItems="center">
                    <AttachMoney />
                    <NoEncryption />
                  </Box>
                }
              >
                <Typography variant="body2">
                  All service prices are <strong>UNLOCKED</strong>, changes to service pricing will apply to order
                </Typography>
              </Alert>
            </Box>
          ) : null}
          {servicePriceLockMixed ? (
            <Box mt={2}>
              <Alert
                severity="warning"
                variant="outlined"
                icon={
                  <Box display="flex" alignItems="center">
                    <AttachMoney />
                    <QuestionMark />
                  </Box>
                }
              >
                <Typography variant="body2" paragraph>
                  Service price locks are <strong>MIXED</strong>, only changes to <strong>UNLOCKED</strong> service pricing
                  will apply to order
                </Typography>
                <Typography variant="body2">
                  The above Difference does not reflect price locks and may not be accurate
                </Typography>
              </Alert>
            </Box>
          ) : null}
        </>
      ) : null}
    </>
  );
};

const Totals = () => {
  const { record: quoteRecord } = useShowContext();

  if (!quoteRecord) return null;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Subtotal</Typography>
        <Typography>{formatCurrency(quoteRecord.totals?.subtotal)}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography>Discount</Typography>
        <Typography>{formatCurrency(quoteRecord.totals?.discount_total)}</Typography>
      </Box>
      <Box my={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Total</Typography>
        <Typography variant="h6">{formatCurrency(quoteRecord.totals?.total)}</Typography>
      </Box>
      <OrderTotalDiff />
    </Box>
  );
};

const QuoteTotals = () => (
  <>
    <Card>
      <Box py={2} px={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Breakdown</Typography>
      </Box>
      <Divider />
      <CardContent>
        <ServiceGroups />
        <Box my={1}>
          <Divider />
        </Box>
        <Products />
        <Box my={1}>
          <Divider />
        </Box>
        <Fees />
      </CardContent>
    </Card>
    <Card component={Box} mt={2}>
      <CardHeader title="Promo Code" />
      <Divider />
      <CardContent>
        <PromoCode />
      </CardContent>
    </Card>
    <Card component={Box} mt={2}>
      <CardHeader title="Totals" />
      <Divider />
      <CardContent>
        <Totals />
      </CardContent>
    </Card>
  </>
);

export default QuoteTotals;
