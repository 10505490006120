import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import _ from 'lodash';

import { MARKET_TIMEZONES } from '@/constants/marketTimezones';

dayjs.extend(tz);

const localServiceGroupsTransform = (data) => {
  const retData = _.cloneDeep(data);

  retData.service_groups = retData.service_groups?.map((serviceGroup) => {
    const startDateTime = dayjs(retData.preferred.start_date)
      .hour(retData.preferred.start_time)
      .minute(0)
      .second(0)
      .millisecond(0);
    const serviceGroupLocations = retData.locations?.map(({ location: { id: locationId } }) => locationId);
    const timezone = retData.locations?.[0].location.timezone ?? MARKET_TIMEZONES[retData.locations?.[0].location.market_id];

    return {
      ...serviceGroup,
      locations: serviceGroupLocations,
      start_datetime: startDateTime.tz(timezone, true).toISOString(),
    };
  });

  return retData;
};

export default localServiceGroupsTransform;
