import React from 'react';

import {
  CreateBase,
  Form,
  ListButton,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TextInput,
  Toolbar,
  email,
  required,
  useNotify,
} from 'react-admin';

import { Box, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { CUSTOMER_HEAR_ABOUT_US, CUSTOMER_SOURCE } from '@/constants/customerAccount';
import { ACCOUNT_TAG_CHOICES } from '@/constants/tags';
import { trim } from 'lodash';

const CustomToolbar = (props) => (
  <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <ListButton resource={resources.CUSTOMERS} label="Cancel" icon={null} />
    <SaveButton label="Create" icon={null} />
  </Toolbar>
);

const CustomerAccountCreate = () => {
  const notify = useNotify();
  const onError = (error) => {
    if (error?.body?.detail) {
      if (typeof error.body.detail === 'string') {
        notify(error?.body?.detail, { type: 'error' });
        return;
      }
      if (error.body.detail[0]?.msg) {
        notify(error.body.detail[0].msg, { type: 'error' });
        return;
      }
      notify(`Unknown Error${error?.message ? ` - ${error.message}` : ''}`, { type: 'error' });
    } else {
      notify(`Unknown Error${error?.message ? ` - ${error.message}` : ''}`, { type: 'error' });
    }
  };

  const transform = ({ first_name: firstName, last_name: lastName, phone, email: emailValue, ...rest }) => ({
    first_name: trim(firstName),
    last_name: trim(lastName),
    phone: trim(phone),
    email: trim(emailValue),
    ...rest,
  });

  return (
    <CreateBase mutationOptions={{ onError }} transform={transform}>
      <Card mt={3} component={Box}>
        <CardHeader title="Create New Customer Account" />

        <Divider />
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput source="first_name" label="First Name" fullWidth validate={[required()]} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="last_name" label="Last Name" fullWidth validate={[required()]} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="phone" label="Phone" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={3}>
              <TextInput source="email" label="Email" fullWidth validate={[required(), email()]} />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="status"
                label="Status"
                choices={[{ id: 'Active', name: 'Active' }]}
                fullWidth
                defaultValue="Active"
                validate={required()}
              />
            </Grid>
          </Grid>

          <Box px={3} py={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Optional</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ReferenceInput
                source="primary_market"
                reference={resources.MARKETS}
                perPage={999}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectInput label="Primary Market" optionText="name" fullWidth />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="source" label="Source" choices={CUSTOMER_SOURCE} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="hear_about_us" label="Hear About Us" choices={CUSTOMER_HEAR_ABOUT_US} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <SelectArrayInput source="tags" choices={ACCOUNT_TAG_CHOICES} />
            </Grid>
          </Grid>

          <Divider />
          <CustomToolbar />
        </Form>
      </Card>
    </CreateBase>
  );
};

export default CustomerAccountCreate;
