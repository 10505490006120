import React, { useContext, useState } from 'react';

import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import mx from 'mixpanel-browser';
import {
  DateInput,
  Form,
  Labeled,
  RadioButtonGroupInput,
  RecordContextProvider,
  SelectInput,
  TextField,
  useDataProvider,
  useNotify,
  useShowContext,
} from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router';

import * as resources from '@/api/resources';
import { MARKET_TIMEZONES } from '@/constants/marketTimezones';
import { AVAILABLE_SERVICES } from '@/constants/quotes';
import { QuoteContext } from '@/providers/Quote';
import formatAddressParts from '@/utils/locations/formatAddressParts';
import quoteHasService from '@/utils/quotes/quoteHasService';
import { Box, Button, Card, CardContent, CardHeader, Divider, FormControlLabel, Switch } from '@mui/material';
import { useMutation } from 'react-query';

dayjs.extend(utc);
dayjs.extend(tz);

const FormControls = () => {
  const { record: quoteRecord } = useShowContext();
  const navigate = useNavigate();
  const { isDirty } = useFormState();

  const navigateBack = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  return (
    <Card>
      <CardContent component={Box} display="flex" justifyContent="space-between">
        <Button variant="contained" color="neutral" onClick={navigateBack}>
          Back
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
};

const arrayRange = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

const TimeSlotInput = () => {
  const [swimlanesOnly, setSwimlanesOnly] = useState(true);

  const rawSwimlaneHours = [8, 11, 14, 17];
  const swimlaneChoices = rawSwimlaneHours.map((hour) => ({
    id: hour,
    name: dayjs().hour(hour).format('h A'),
  }));

  const rawNonSwimlaneHours = arrayRange(8, 17).filter((hour) => !rawSwimlaneHours.includes(hour));
  const nonSwimlaneChoices = rawNonSwimlaneHours.map((hour) => ({
    id: hour,
    name: dayjs().hour(hour).format('h A'),
  }));

  const choices = [...swimlaneChoices, ...(!swimlanesOnly ? nonSwimlaneChoices : [])].sort((a, b) => a.id - b.id);

  return (
    <>
      <Box>
        <FormControlLabel
          label="Show Non Swimlane Hours"
          control={<Switch value={swimlanesOnly} onChange={() => setSwimlanesOnly(!swimlanesOnly)} />}
        />
      </Box>
      <Box maxWidth={480}>
        <RadioButtonGroupInput
          required
          source="hour"
          choices={choices}
          sx={{ '& .MuiFormControlLabel-root': { width: 90 } }}
        />
      </Box>
    </>
  );
};

const ServiceGroupForm = () => {
  const { record: quoteRecord } = useShowContext();
  const { isLongDistanceV2, locations } = useContext(QuoteContext);

  const { watch } = useFormContext();

  if (!quoteRecord || !locations) return null;

  let choices;
  if (quoteRecord.partner_id === 'PODS') {
    // For PODS, start with available services, then filter out any services already on quote
    choices = AVAILABLE_SERVICES.PODS;
    choices = choices.filter((choice) => !quoteHasService(quoteRecord, choice.id));
  } else if (isLongDistanceV2) {
    choices = AVAILABLE_SERVICES.LD;
  } else {
    // For local quotes, start with available services, then filter out any services already on quote
    choices = AVAILABLE_SERVICES.LOCAL;
    choices = choices.filter((choice) => !quoteHasService(quoteRecord, choice.id));
  }

  if (choices?.length === 0) {
    return (
      <Card component={Box} mb={3}>
        <CardContent>There are no more available services to add to this quote</CardContent>
      </Card>
    );
  }

  const selectedLocationId = watch('location_id');

  const { location: locObj } = locations.find((loc) => loc.location_id === selectedLocationId) ?? {};
  const locationTimezone = locObj ? locObj.timezone ?? MARKET_TIMEZONES[locObj.market_id] : null;

  return (
    <Card component={Box} mb={3}>
      <CardHeader title="Add Service" />
      <Divider />
      <CardContent>
        <Box>
          <SelectInput source="type" choices={choices} required />
        </Box>
        <Box>
          <DateInput source="date" required />
        </Box>
        <Box>
          <TimeSlotInput />
        </Box>
        <Box display="flex" gap={2}>
          <SelectInput
            label="Address"
            source="location_id"
            choices={
              locations?.map(({ location }) => ({
                id: location.id,
                name: formatAddressParts(location),
              })) ?? []
            }
          />
          {locationTimezone ? (
            <RecordContextProvider value={{ locationTimezone, localTime: dayjs().tz(locationTimezone).format('h:mm A') }}>
              <Labeled label="Timezone" mt={1.5}>
                <TextField source="locationTimezone" />
              </Labeled>
              <Labeled label="Local Time" mt={1.5}>
                <TextField source="localTime" />
              </Labeled>
            </RecordContextProvider>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  );
};

const AddServiceGroup = () => {
  const { record: quoteRecord, refetch } = useShowContext();
  const { locations } = useContext(QuoteContext);

  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const { mutate: operationUpdate } = useMutation(([resource, params]) => dataProvider.operationUpdate(resource, params));

  const navigateOverview = () => navigate(`/quotes/${quoteRecord.id}/edit`);

  const onSuccess = () => {
    mx.track('Order Management - Quote - Service Group created', {
      quoteId: quoteRecord?.id,
    });
    refetch();
    navigateOverview();
    notify('Service Group added on quote', { type: 'success' });
  };

  const onError = (error) => {
    mx.track('Order Management - Quote - Error creating Service Group', {
      quoteId: quoteRecord?.id,
      error,
    });
    notify(`Error occurred adding Service Group on quote - ${error}`, { type: 'error' });
  };

  const onSubmit = (data) => {
    const { location } = locations.find((loc) => loc.location_id === data.location_id);
    const locationTimezone = location.timezone ?? MARKET_TIMEZONES[location.market_id];
    const startDatetime = dayjs(data.date).tz(locationTimezone, true).hour(data.hour).toISOString();

    const operationsNeeded = [
      {
        op: 'add',
        path: '/service_groups',
        value: {
          service_codes: [data.type],
          locations: [data.location_id],
          start_datetime: startDatetime,
        },
      },
    ];

    const params = {
      data: operationsNeeded,
      id: quoteRecord?.id,
      meta: {
        operationPatch: true,
      },
    };

    operationUpdate([resources.QUOTES, params], { onSuccess, onError });
  };

  return (
    <Form record={{}} onSubmit={onSubmit}>
      <ServiceGroupForm />
      <FormControls />
    </Form>
  );
};

export default AddServiceGroup;
