import React from 'react';

import { LocalShipping, PeopleAlt } from '@mui/icons-material';

import { SERVICES } from './serviceName';

const QUOTE_NON_PARTNER_TEMPLATES = {
  HOURLY_LABOR: {
    id: 'HOURLY_LABOR',
    name: 'Hourly Labor',
    serviceGroups: [{ services: [SERVICES.HOURLYLABOR.id] }],
    inventoryRequired: false,
    icons: [<PeopleAlt key="hourlyLaborPeople" />],
  },
  MOVING_SERVICES: {
    id: 'MOVING_SERVICES',
    name: 'Moving Services',
    serviceGroups: [{ services: [SERVICES.LOADINGUNLOADING.id] }],
    inventoryRequired: true,
    icons: [<PeopleAlt key="movingServicePeople" />],
  },
  FULL_SERVICE: {
    id: 'FULL_SERVICE',
    name: 'Full Service',
    serviceGroups: [{ services: [SERVICES.LOADINGUNLOADING.id, SERVICES.TRANSIT.id] }],
    inventoryRequired: true,
    icons: [<PeopleAlt key="fullServicePeople" />, <LocalShipping key="fullServiceTruck" />],
  },
  LONG_DISTANCE: {
    id: 'LONG_DISTANCE',
    name: 'Long Distance',
    serviceGroups: [
      { services: [SERVICES.LDLOADING.id] },
      { services: [SERVICES.LDTRANSIT.id] },
      { services: [SERVICES.LDUNLOADING.id] },
    ],
    inventoryRequired: true,
    icons: [
      <PeopleAlt key="longDistancePeople1" />,
      <LocalShipping key="longDistanceTruck" />,
      <PeopleAlt key="longDistancePeople2" />,
    ],
  },
};

const QUOTE_PARTNER_TEMPLATES = {
  PODS: {
    LOADING: {
      id: 'PODS_LOADING',
      name: 'Loading',
      serviceGroups: [{ services: [SERVICES.LOADING.id] }],
      inventoryRequired: false,
      icons: [<PeopleAlt key="loadingPeople" />],
    },
    UNLOADING: {
      id: 'PODS_UNLOADING',
      name: 'Unloading',
      serviceGroups: [{ services: [SERVICES.UNLOADING.id] }],
      inventoryRequired: false,
      icons: [<PeopleAlt key="unloadingPeople" />],
    },
    LOADING_UNLOADING: {
      id: 'PODS_LOADING_UNLOADING',
      name: 'Loading / Unloading',
      serviceGroups: [{ services: [SERVICES.LOADING.id] }, { services: [SERVICES.UNLOADING.id] }],
      inventoryRequired: false,
      icons: [<PeopleAlt key="loadingPeople" />, <PeopleAlt key="unloadingPeople" />],
    },
  },
  ZIPPY: {
    LOADING: {
      id: 'ZIPPY_LOADING',
      name: 'Loading',
      serviceGroups: [{ services: [SERVICES.LOADING.id] }, { services: [SERVICES.TRANSIT.id] }],
      inventoryRequired: true,
      icons: [<PeopleAlt key="loadingPeople" />, <LocalShipping key="loadingTruck" />],
    },
    UNLOADING: {
      id: 'ZIPPY_UNLOADING',
      name: 'Unloading',
      serviceGroups: [{ services: [SERVICES.TRANSIT.id] }, { services: [SERVICES.UNLOADING.id] }],
      inventoryRequired: true,
      icons: [<LocalShipping key="unloadingTruck" />, <PeopleAlt key="unloadingPeople" />],
    },
  },
  OFFERPAD: {
    // Special Offerpad case, if OP and Hourly Labor, use LOADING as service code due to legacy BRE setup
    // ID matches non partner ID because we handle it exactly the same as non partner
    // inventoryRequired also true due to Offerpad config
    // Rest of Offerpad templates are standard non partner templates
    HOURLYLABOR: {
      id: 'HOURLY_LABOR',
      name: 'Hourly Labor',
      serviceGroups: [{ services: [SERVICES.LOADING.id] }],
      inventoryRequired: true,
      icons: [<PeopleAlt key="hourlyLaborPeople" />],
    },
  },
};

const QUOTE_TEMPLATE_UNAVAILABLE_REASONS = {
  SINGLE_MARKET: 'Single market required',
  MULTIPLE_MARKETS: 'Multiple markets required',
  SINGLE_LOCATION: 'Single location required',
  TWO_LOCATIONS: 'Two locations required',
  MULTIPLE_LOCATIONS: 'Multiple locations required',
  LABOR_ONLY_MARKET: 'Market is labor only',
  PODS_MARKET: 'PODS Market required',
  INACTIVE_MARKET: 'Market is inactive',
  BELLHOP_NATION_MARKET: 'Market is Bellhop Nation',
  ZIPPY_LOCATIONS: 'Zippyshell quotes require exactly 2 locations',
  LONG_DISTANCE: 'Total mileage must be greater than 60 miles',
  CROSS_MARKET: 'Cross market local moves require total mileage less than 60 miles',
};

const QUOTE_TYPES = {
  BELLHOP: 'bellhop',
  PARTNER: 'partner',
  AFFILIATE: 'affiliate',
};

export { QUOTE_NON_PARTNER_TEMPLATES, QUOTE_PARTNER_TEMPLATES, QUOTE_TEMPLATE_UNAVAILABLE_REASONS, QUOTE_TYPES };
