import offerpadTemplates from './offerpad';
import podsTemplates from './pods';
import zippyTemplates from './zippy';
import zippyCorpReloTemplates from './zippyCorpRelo';

const partnerServiceTemplates = ({ partner, locations, marketsData, uniqueMarkets, routeData }) => {
  if (partner === 'PODS') {
    return podsTemplates({ locations, marketsData, uniqueMarkets });
  }

  if (partner === 'OFFERPAD') {
    return offerpadTemplates({ locations, marketsData, uniqueMarkets, routeData });
  }

  if (partner === 'ZIPPY') {
    return zippyTemplates({ locations, marketsData, uniqueMarkets });
  }

  if (partner === 'ZIPPYCORPRELO') {
    return zippyCorpReloTemplates({ locations, marketsData, uniqueMarkets });
  }

  return [
    {
      id: 'partner-not-configured',
      name: 'Partner Not Configured',
      available: false,
      service_groups: [],
    },
  ];
};

export default partnerServiceTemplates;
