import React from 'react';

import mx from 'mixpanel-browser';
import {
  DateField,
  Labeled,
  NumberField,
  TextField,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';

import { Avatar, Box, Button, Card, CardContent, Divider, Grid, Tooltip } from '@mui/material';

import * as resources from '@/api/resources';
import { THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION, THIRD_PARTY_CREW_ACCOUNT_TAB } from '@/constants/thirdPartyCrewAccount';
import MarketNameField from '@/fields/MarketNameField';

import ThirdPartyCrewDetailsSectionHeader from '../Common/SectionHeader';

const ThirdPartyCrewRemovePhotoButton = () => {
  const { record: accountRecord } = useShowContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const onError = (error) => {
    notify('Error occurred removing third party crew photo', { type: 'error' });
    mx.track('Error removing third party crew photo', error.body ?? error);
  };

  const onSuccess = () => {
    refresh();
    notify('Third Party Crew photo removed', { type: 'success' });
  };

  const [update] = useUpdate(
    resources.THIRD_PARTY_CREW,
    {
      id: accountRecord?.id,
      data: { image_url: '' },
    },
    {
      onSuccess,
      onError,
    },
  );

  const canRemoveProPhoto = permissions.tools?.hqadmin?.third_party_crew_accounts?.update && accountRecord?.image_url;

  return canRemoveProPhoto ? (
    <Box textAlign="center" mt={2}>
      <Tooltip title="Third Party Crew must re-upload a photo from the mobile app">
        <Button variant="outlined" color="error" onClick={() => update()}>
          Remove Photo
        </Button>
      </Tooltip>
    </Box>
  ) : null;
};

const ThirdPartyCrewDetailsOverviewSection = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Card>
      <ThirdPartyCrewDetailsSectionHeader
        title="Overview"
        tab={THIRD_PARTY_CREW_ACCOUNT_TAB.DETAILS}
        section={THIRD_PARTY_CREW_ACCOUNT_DETAIL_SECTION.OVERVIEW}
      />

      <Divider />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="center" mt={2}>
              <Avatar
                component={Box}
                src={record.image_url}
                sx={{
                  width: 100,
                  height: 100,
                }}
              />
            </Box>
            <ThirdPartyCrewRemovePhotoButton />
          </Grid>

          <Grid item xs={10} container spacing={2}>
            <Grid item xs={4}>
              <Labeled>
                <TextField source="name" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <TextField source="market_manager" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <TextField source="status" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <MarketNameField source="primary_market" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <NumberField source="truck_count" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <DateField source="joined_date" emptyText="N/A" />
              </Labeled>
            </Grid>

            <Grid item xs={4}>
              <Labeled>
                <TextField source="account_record_type_name" emptyText="N/A" />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ThirdPartyCrewDetailsOverviewSection;
