import React, { useContext, useState } from 'react';

import mx from 'mixpanel-browser';
import { arrayOf, bool, oneOf, shape } from 'prop-types';
import {
  FunctionField,
  Labeled,
  RecordContextProvider,
  TextField,
  useDelete,
  useGetList,
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
  useReference,
  useRefresh,
  useShowContext,
} from 'react-admin';

import { Edit, ExpandLess, ExpandMore, RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, CircularProgress, Collapse, Divider, Grid, IconButton, Typography } from '@mui/material';

import * as resources from '@/api/resources';
import { PRICE_ADJUSTMENT_TYPES, SETTLEMENT_TYPES } from '@/constants/adjustmentName';
import { ORDER_ITEM_ACTIVE_STATUSES } from '@/constants/orderItemStatus';
import AdjustmentNameField from '@/fields/AdjustmentNameField';
import CurrencyField from '@/fields/CurrencyField';
import NullableReferenceField from '@/fields/NullableReferenceField';
import ProductNameField from '@/fields/ProductNameField';
import SlotAccountNameField from '@/fields/SlotAccountNameField';
import TextFieldWrapper from '@/fields/TextFieldWrapper';
import { OrderActionContext } from '@/providers/OrderAction';
import getAdjustmentName from '@/utils/adjustmentName/getAdjustmentName';
import formatCurrency from '@/utils/currency/formatCurrency';
import objectSum from '@/utils/objectSum';
import getServiceName from '@/utils/serviceName/getServiceName';
import { SettlementCoverageTypeField, SettlementPartyAtFaultField, SettlementReasonField } from './Settlement';

const Job = ({ isCompleted }) => {
  const job = useRecordContext();
  const { referenceRecord } = useReference({
    reference: resources.JOBS,
    id: job.order_item_id,
    options: { enabled: Boolean(job) },
  });

  if (!job || !referenceRecord) return null;

  return (
    <Box display="flex" justifyContent="space-between">
      <FunctionField
        render={(record) =>
          job.pricing_strategy === 'HOURLY' ? (
            <TextFieldWrapper
              value={`${getServiceName(record.product_id)} - ${
                isCompleted
                  ? Number(referenceRecord?.quantity || 0)?.toFixed(2)
                  : Number(referenceRecord?.duration || 0)?.toFixed(2)
              } hrs x ${formatCurrency(referenceRecord.unitprice)}`}
              textFieldProps={{ variant: 'body1' }}
            />
          ) : (
            <TextFieldWrapper
              value={`${getServiceName(record.product_id)} - Fixed Pricing`}
              textFieldProps={{ variant: 'body1' }}
            />
          )
        }
      />
      <CurrencyField source="monetary_value" />
    </Box>
  );
};

Job.propTypes = {
  isCompleted: bool.isRequired,
};

const Product = () => {
  const { setAction, setMetadata } = useContext(OrderActionContext);
  const { record: { id: orderId } = {} } = useShowContext();
  const product = useRecordContext();

  const { permissions } = usePermissions();

  const { data: productsData } = useGetList(
    resources.ORDERS,
    { meta: { subResource: 'products', resourceId: orderId } },
    { enabled: Boolean(orderId) },
  );

  const productItem = productsData?.find((item) => item.id === product.order_item_id);

  const updateProduct = () => {
    setAction('edit_product');
    setMetadata({ productId: productItem.id });
  };

  const canEditProduct = permissions?.tools?.hqadmin?.order_products?.update;

  if (!product || !productItem) return null;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <RecordContextProvider value={productItem}>
        <Box display="flex" alignItems="center">
          <ProductNameField source="product_id" />
          {canEditProduct ? (
            <Box ml={2} my={0.5}>
              <Button size="small" variant="outlined" startIcon={<Edit />} onClick={updateProduct}>
                Update
              </Button>
            </Box>
          ) : null}
        </Box>
      </RecordContextProvider>
      <CurrencyField source="monetary_value" />
    </Box>
  );
};

const Subtotal = () => {
  const invoice = useRecordContext();

  if (!invoice) return null;
  const jobsTotal = invoice.jobs?.reduce((prev, curr) => prev + curr.monetary_value, 0);
  const productsTotal = invoice.products?.reduce((prev, curr) => prev + curr.monetary_value, 0);
  const subtotal = jobsTotal + productsTotal;

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography>Subtotal</Typography>
      <RecordContextProvider value={{ subtotal }}>
        <CurrencyField source="subtotal" />
      </RecordContextProvider>
    </Box>
  );
};

const Fee = () => {
  const { record: { id: orderId } = {} } = useShowContext();
  const fee = useRecordContext();

  const { data: adjustmentsData } = useGetList(
    resources.ORDERS,
    { meta: { subResource: 'adjustments', resourceId: orderId }, filter: { status: ORDER_ITEM_ACTIVE_STATUSES } },
    { enabled: Boolean(orderId) },
  );

  const feeItem = adjustmentsData?.find((item) => item.id === fee.order_item_id);

  if (!adjustmentsData || !feeItem) return null;

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gap={2} alignItems="center">
        <RecordContextProvider value={feeItem}>
          <AdjustmentNameField source="product_id" />
          <TextField source="description" variant="body2" />
        </RecordContextProvider>
      </Box>
      <CurrencyField source="monetary_value" />
    </Box>
  );
};

const Adjustment = () => {
  const { record: { id: orderId } = {} } = useShowContext();
  const adjustment = useRecordContext();
  const { permissions } = usePermissions();
  const [deleteOne, { isLoading: deleteIsLoading }] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();

  const [expanded, setExpanded] = useState(false);

  const { data: adjustmentsData } = useGetList(
    resources.ORDERS,
    { meta: { subResource: 'adjustments', resourceId: orderId } },
    { enabled: Boolean(orderId) },
  );

  const { data: settlementsData } = useGetList(
    resources.ORDERS,
    { meta: { subResource: 'settlements', resourceId: orderId, resourceVersion: 'v2' } },
    { enabled: Boolean(orderId) },
  );

  const adjustmentItem = adjustmentsData?.find((item) => item.id === adjustment.order_item_id);
  const settlementItem = settlementsData?.find((item) => item.id === adjustment.order_item_id);
  const hasV2SettlementData =
    settlementItem?.settlement_coverage_type ||
    settlementItem?.settlement_party_at_fault ||
    settlementItem?.settlement_reason;

  const onRemoveSettled = (data, error) => {
    const adjustmentName = getAdjustmentName(adjustmentItem.product_id);
    if (error) {
      notify(`Failed to remove ${adjustmentName} - ${error.status} - ${error.message}`, { type: 'error' });
    } else if (data) {
      notify(`Removed ${adjustmentName} successfully`, { type: 'success' });

      mx.track(`Order Management - ${adjustmentName} Removed`);
      refresh();
    }
  };

  const removeAdjustment = () => {
    const adjustmentType = adjustmentItem.product_id === 'SETTLEMENT' ? 'settlements' : 'price_adjustments';
    deleteOne(
      resources.ORDERS,
      {
        id: orderId,
        meta: {
          subResource: adjustmentType,
          resourceId: adjustment.order_item_id,
          resourceVersion: SETTLEMENT_TYPES.includes(adjustmentItem?.product_id) ? 'v2' : null,
        },
      },
      { onSettled: onRemoveSettled },
    );
  };

  const isPriceAdjustment = PRICE_ADJUSTMENT_TYPES.includes(adjustmentItem?.product_id);
  const canDeletePriceAdjustment = permissions?.tools?.hqadmin?.order_price_adjustments?.delete;
  const isSettlement = SETTLEMENT_TYPES.includes(adjustmentItem?.product_id);
  const canDeleteSettlement = permissions?.tools?.hqadmin?.order_settlements?.delete;

  const canDeleteAdjustment = (isPriceAdjustment && canDeletePriceAdjustment) || (isSettlement && canDeleteSettlement);

  if (!adjustmentsData || !adjustmentItem) return null;

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <RecordContextProvider value={settlementItem ?? adjustmentItem}>
          <Box display="flex" alignItems="center">
            {settlementItem ? (
              <Box>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <IconButton onClick={() => setExpanded(!expanded)}>
                      {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Box>
                  <AdjustmentNameField source="product_id" />
                </Box>
              </Box>
            ) : (
              <AdjustmentNameField source="product_id" />
            )}

            {canDeleteAdjustment ? (
              <Box ml={2} my={0.5}>
                {deleteIsLoading ? (
                  <CircularProgress size={32} />
                ) : (
                  <IconButton color="accent" variant="outlined" onClick={removeAdjustment}>
                    <RemoveCircleOutline />
                  </IconButton>
                )}
              </Box>
            ) : null}
          </Box>
        </RecordContextProvider>
        <CurrencyField source="monetary_value" />
      </Box>
      {settlementItem ? (
        <RecordContextProvider value={settlementItem}>
          <Collapse in={expanded} component={Box} ml={6}>
            <Grid container mb={2}>
              {hasV2SettlementData ? (
                <>
                  <Grid item xs={3}>
                    <Labeled>
                      <SettlementCoverageTypeField source="settlement_coverage_type" label="Coverage Type" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={3}>
                    <Labeled>
                      <SettlementPartyAtFaultField source="settlement_party_at_fault" label="Party At Fault" />
                    </Labeled>
                  </Grid>
                  <Grid item xs={3}>
                    <Labeled>
                      <SettlementReasonField source="settlement_reason" label="Reason" />
                    </Labeled>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <Labeled>
                  <TextField source="description" emptyText="N/A" />
                </Labeled>
              </Grid>
            </Grid>
          </Collapse>
        </RecordContextProvider>
      ) : null}
    </>
  );
};

const Tips = ({ invoiceTips }) => {
  const { record: { id: orderId } = {} } = useShowContext();

  const { data: adjustmentsData } = useGetList(
    resources.ORDERS,
    { meta: { subResource: 'adjustments', resourceId: orderId } },
    { enabled: Boolean(orderId) },
  );

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (!adjustmentsData) return null;

  return (
    <>
      <Collapse in={expanded} component={Box} ml={6}>
        <Box mb={2}>
          {invoiceTips.map((tip) => {
            const matchingAdjustment = adjustmentsData.find((adjustment) => tip.order_item_id === adjustment.id);
            return (
              <RecordContextProvider value={matchingAdjustment} key={matchingAdjustment.id}>
                <Tip />
              </RecordContextProvider>
            );
          })}
        </Box>
      </Collapse>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <IconButton onClick={handleExpandClick}>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </Box>
          <Typography>Tips</Typography>
        </Box>
        <RecordContextProvider value={{ total: invoiceTips.reduce(objectSum('monetary_value'), 0) }}>
          <CurrencyField source="total" />
        </RecordContextProvider>
      </Box>
    </>
  );
};

Tips.propTypes = {
  invoiceTips: arrayOf(shape({})).isRequired,
};

const Tip = () => {
  const tip = useRecordContext();
  const { data: jobSlotData } = useGetOne(resources.JOB_SLOTS, { id: tip.tip_job_slot_id });

  if (!tip || !jobSlotData) return null;

  return (
    <Box display="flex" alignItems="center" my={1}>
      <RecordContextProvider value={jobSlotData}>
        <NullableReferenceField source="account_id" reference={resources.ACCOUNTS}>
          <SlotAccountNameField />
        </NullableReferenceField>
      </RecordContextProvider>
      <Box ml="auto">
        <CurrencyField source="unitprice" />
      </Box>
    </Box>
  );
};

const Breakdown = ({ status }) => {
  const { record: { id: orderId } = {} } = useShowContext();

  const [noInvoiceItems, setNoInvoiceItems] = useState(false);
  const notify = useNotify();

  const onError = (error) => {
    if (error.status === 404 && error.message?.includes('No invoiceable items found')) {
      setNoInvoiceItems(true);
    } else {
      notify(`Error loading ${status} invoice`, { type: 'error' });
    }
  };

  const { data } = useGetOne(
    resources.ORDERS,
    { id: orderId, meta: { subResource: 'invoice', filter: { invoice_status: status } } },
    { enabled: Boolean(orderId), onError, retry: false },
  );

  const isCompleted = status === 'completed';

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (noInvoiceItems) {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" ml={2}>
          <Typography>No invoiceable items found</Typography>
        </Box>
        <RecordContextProvider value={{ total: 0 }}>
          <CurrencyField source="total" />
        </RecordContextProvider>
      </Box>
    );
  }

  if (!orderId || !data) return null;

  return (
    <>
      <Collapse in={expanded} component={Box} ml={6}>
        <Box mb={1}>
          {data?.jobs.map((job, idx) => (
            <>
              {idx !== 0 && <Divider />}
              <Box my={1}>
                <RecordContextProvider value={job} key={job.order_item_id}>
                  <Job isCompleted={isCompleted} />
                </RecordContextProvider>
              </Box>
            </>
          ))}
          {data?.products.map((product) => (
            <>
              <Divider />
              <Box my={1}>
                <RecordContextProvider value={product} key={product.order_item_id}>
                  <Product />
                </RecordContextProvider>
              </Box>
            </>
          ))}
        </Box>
        <Divider />
        <Box my={1}>
          <RecordContextProvider value={data}>
            <Subtotal />
          </RecordContextProvider>
        </Box>
        {data?.fees?.length ? (
          <>
            <Divider />
            <Box my={1}>
              {data?.fees.map((fee) => (
                <RecordContextProvider value={fee} key={fee.order_item_id}>
                  <Fee />
                </RecordContextProvider>
              ))}
            </Box>
          </>
        ) : null}
        {isCompleted ? (
          <>
            {data?.reductions?.length ? (
              <>
                <Divider />
                <Box my={1}>
                  {data?.reductions.map((adjustment) => (
                    <RecordContextProvider value={adjustment} key={adjustment.order_item_id}>
                      <Adjustment />
                    </RecordContextProvider>
                  ))}
                </Box>
                <Divider />
              </>
            ) : null}
            {data.tips.length > 0 ? (
              <>
                <Divider />
                <Box mt={1}>
                  <Tips invoiceTips={data?.tips} />
                </Box>
              </>
            ) : null}
          </>
        ) : null}
      </Collapse>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={expanded ? 2 : 0}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <IconButton onClick={handleExpandClick}>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </Box>
          <Typography>Total</Typography>
        </Box>
        <RecordContextProvider value={data}>
          <CurrencyField source="total" />
        </RecordContextProvider>
      </Box>
    </>
  );
};

Breakdown.propTypes = {
  status: oneOf(['scheduled', 'completed']).isRequired,
};

export default Breakdown;
