import { MARKET_BELLHOP_NATION, MARKET_STATUS } from '@/constants/market';
import {
  QUOTE_NON_PARTNER_TEMPLATES,
  QUOTE_PARTNER_TEMPLATES,
  QUOTE_TEMPLATE_UNAVAILABLE_REASONS,
} from '@/constants/quoteTemplates';

const { FULL_SERVICE, LONG_DISTANCE, MOVING_SERVICES } = QUOTE_NON_PARTNER_TEMPLATES;

const offerpadTemplates = ({ locations, marketsData, uniqueMarkets, routeData }) => {
  if (!locations || locations?.length === 0) {
    // No locations, all templates unavailable
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...FULL_SERVICE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS, QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_MARKET],
      },
      {
        ...MOVING_SERVICES,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS, QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_MARKET],
      },
      {
        ...LONG_DISTANCE,
        available: false,
        reasons: [
          QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS,
          QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS,
        ],
      },
    ];
  }

  if (marketsData?.every((market) => market.status === MARKET_STATUS.INACTIVE.id)) {
    // All markets inactive, no templates available
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.INACTIVE_MARKET],
      },
      {
        ...FULL_SERVICE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.INACTIVE_MARKET],
      },
      {
        ...MOVING_SERVICES,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.INACTIVE_MARKET],
      },
      {
        ...LONG_DISTANCE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS],
      },
    ];
  }

  if (uniqueMarkets?.length > 1) {
    // No route data - default to all except hourly labor
    if (!routeData) {
      return [
        {
          ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
          available: false,
          reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
        },
        {
          ...FULL_SERVICE,
          available: true,
        },
        {
          ...MOVING_SERVICES,
          available: true,
        },
        {
          ...LONG_DISTANCE,
          available: true,
        },
      ];
    }

    // Multiple markets - < 60 miles - local cross market
    if (routeData.driving_distance && routeData.driving_distance <= 60) {
      return [
        {
          ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
          available: false,
          reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
        },
        {
          ...FULL_SERVICE,
          available: true,
        },
        {
          ...MOVING_SERVICES,
          available: true,
        },
        {
          ...LONG_DISTANCE,
          available: false,
          reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.LONG_DISTANCE],
        },
      ];
    }

    // Multiple markets - > 60 miles - normal LD
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...FULL_SERVICE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_MARKET, QUOTE_TEMPLATE_UNAVAILABLE_REASONS.CROSS_MARKET],
      },
      {
        ...MOVING_SERVICES,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_MARKET, QUOTE_TEMPLATE_UNAVAILABLE_REASONS.CROSS_MARKET],
      },
      {
        ...LONG_DISTANCE,
        available: true,
      },
    ];
  }

  if (uniqueMarkets.includes(MARKET_BELLHOP_NATION)) {
    // Only one market and it is BHN, no templates available
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.BELLHOP_NATION_MARKET],
      },
      {
        ...FULL_SERVICE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.BELLHOP_NATION_MARKET],
      },
      {
        ...MOVING_SERVICES,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.BELLHOP_NATION_MARKET],
      },
      {
        ...LONG_DISTANCE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS],
      },
    ];
  }

  if (locations?.length === 1) {
    // Single location, only hourly labor available
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
        available: true,
      },
      {
        ...FULL_SERVICE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS],
      },
      {
        ...MOVING_SERVICES,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS],
      },
      {
        ...LONG_DISTANCE,
        available: false,
        reasons: [
          QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_LOCATIONS,
          QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS,
        ],
      },
    ];
  }

  // Multiple locations, single market
  if (marketsData?.[0]?.is_labor_only) {
    // Labor only market, only moving services (loading/unloading no truck) available
    return [
      {
        ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
      },
      {
        ...FULL_SERVICE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.LABOR_ONLY_MARKET],
      },
      {
        ...MOVING_SERVICES,
        available: true,
      },
      {
        ...LONG_DISTANCE,
        available: false,
        reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS],
      },
    ];
  }

  // Normal market, full service and moving services available
  return [
    {
      ...QUOTE_PARTNER_TEMPLATES.OFFERPAD.HOURLYLABOR,
      available: false,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.SINGLE_LOCATION],
    },
    {
      ...FULL_SERVICE,
      available: true,
    },
    {
      ...MOVING_SERVICES,
      available: true,
    },
    {
      ...LONG_DISTANCE,
      available: false,
      reasons: [QUOTE_TEMPLATE_UNAVAILABLE_REASONS.MULTIPLE_MARKETS],
    },
  ];
};

export default offerpadTemplates;
