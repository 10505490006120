import React from 'react';

import { useRecordContext } from 'react-admin';

import { Alert, Box } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

const OrderAlerts = () => {
  const record = useRecordContext();

  if (!record) return null;

  const {
    id: orderId,
    start_date_time: startDateTime,
    start_time_zone: startTimezone,
    transaction_failure_review: transactionFailureReview,
  } = record;

  if (!orderId) return null;

  const localOrderStartDate = dayjs(startDateTime).tz(startTimezone);

  return transactionFailureReview ? (
    <Box mt={3}>
      <Alert severity="error" variant="filled">
        Transaction Failure - Order will be cancelled at 6pm EST on{' '}
        {localOrderStartDate.subtract(1, 'day').format('MM/DD/YYYY')} (day before move)
      </Alert>
    </Box>
  ) : null;
};

export default OrderAlerts;
