import React from 'react';

import { Link, useCreatePath, useRecordContext } from 'react-admin';

import { ExitToApp } from '@mui/icons-material';
import { Box, Card, Grid, Typography } from '@mui/material';

import * as resources from '@/api/resources';

import OrderAlerts from './OrderAlerts';

const OrderHeader = () => {
  const record = useRecordContext();

  const createPath = useCreatePath();

  if (!record) return null;

  const { id: orderId, confirmation_id: orderConfirmationId, status: orderStatus } = record;

  if (!orderId) return null;

  return (
    <>
      <Card px={3} py={2} component={Box}>
        <Grid container display="flex" alignItems="center">
          <Grid item xs={4}>
            <Typography variant="h5" component="span" pr={1}>
              Order #:
            </Typography>
            <Link
              to={createPath({
                resource: resources.ORDERS,
                type: 'show',
                id: orderId,
              })}
            >
              <Box display="inline-flex" alignItems="center">
                <Typography variant="h5" mr={1}>
                  {orderConfirmationId}
                </Typography>
                <ExitToApp fontSize="small" />
              </Box>
            </Link>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center">
            <Typography variant="h5" component={Box}>
              {`Status: ${orderStatus}`}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <OrderAlerts />
    </>
  );
};

export default OrderHeader;
